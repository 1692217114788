<template>
  <div>
    <title-bar :title="title" />
    <div class="ad-card-box">
      <van-card
        :title="comboInfo.name"
        :desc="comboInfo.summary"
        class="card-item"
      >
        <template #thumb>
          <van-image fit="fill" :src="comboInfo.logoUrl" />
        </template>
      </van-card>
    </div>
    <van-form class="form">
      <div v-show="type == 2">
        <div class="block-title">{{ $t("请选择要修改的网站") }}</div>
        <van-cell-group class="block-bottom">
          <van-cell
            :key="item.id"
            v-for="item in comboInfo.sites"
            :title="item.site.name"
            value-class="site-val"
            clickable
            @click="item.selected = !item.selected"
          >
            <van-checkbox :value="item.selected" />
          </van-cell>
        </van-cell-group>
      </div>
      <div class="block-title">{{ $t("请选择要修改的信息") }}</div>
      <van-grid
        column-num="3"
        :border="false"
        :center="false"
        class="form-items-box block-bottom"
      >
        <van-grid-item use-slot :key="item.name" v-for="item in formItemsList">
          <van-checkbox v-model="item.selected" class="muli-row">{{
            item.name
          }}</van-checkbox>
        </van-grid-item>
      </van-grid>
      <van-field
        :label="$t('时间段')"
        v-show="formItems.timeFragments.selected"
        :rules="[{ required: true }]"
      >
        <template #input>
          <van-checkbox-group
            v-model="data.timeFragments"
            direction="horizontal"
          >
            <van-checkbox :key="tf" v-for="tf in timeFragments" :name="tf">{{
              tf
            }}</van-checkbox>
          </van-checkbox-group>
        </template>
      </van-field>
      <van-field
        :label="$t('地区')"
        :placeholder="$t('请输入地区')"
        v-show="formItems.street.selected"
        v-model="data.street"
      />
      <van-field
        :label="$t('类型')"
        v-show="formItems.adType.selected"
        :rules="[{ required: true }]"
      >
        <template #input>
          <van-radio-group v-model="data.adType" direction="horizontal">
            <van-radio :name="1">{{ $t("按摩店") }}</van-radio>
            <van-radio :name="2">{{ $t("住家店") }}</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        v-model="data.adTel"
        v-show="formItems.adTel.selected"
        :label="$t('广告电话')"
        :placeholder="$t('请输入广告电话')"
        type="digit"
        :rules="[{ required: true }]"
      />
      <van-field
        v-model="data.privateTel"
        v-show="formItems.privateTel.selected"
        :label="$t('私人电话')"
        :placeholder="$t('请输入私人电话')"
        type="digit"
      />
      <van-field
        v-model="data.wechat"
        v-show="formItems.wechat.selected"
        :label="$t('微信')"
        :placeholder="$t('请输入微信号')"
      />
      <van-field
        v-model="data.name"
        v-show="formItems.name.selected"
        :label="$t('姓名')"
        :placeholder="$t('请输入姓名')"
      />
      <van-field
        v-model="data.age"
        v-show="formItems.age.selected"
        type="digit"
        :label="$t('年龄')"
        :placeholder="$t('请输入年龄')"
      />
      <van-field
        v-model="data.height"
        v-show="formItems.height.selected"
        type="digit"
        :label="$t('身高')"
        :placeholder="$t('请输入身高（单位cm）')"
      />
      <van-field
        v-model="data.title"
        v-show="formItems.title.selected"
        :label="$t('标题')"
        :placeholder="$t('请输入广告标题')"
      />
      <van-field
        v-model="data.content"
        v-show="formItems.content.selected"
        :label="$t('内容')"
        type="textarea"
        rows="5"
        autosize
        :placeholder="$t('请输入广告内容')"
      />
      <van-field :label="$t('图片来源')" v-show="formItems.imageUrls.selected">
        <template #input>
          <van-radio-group v-model="imgSourceType" direction="horizontal">
            <van-radio :name="1">{{ $t("本地上传") }}</van-radio>
            <van-radio :name="2">{{ $t("图库选择") }}</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        :label="$t('本地图片')"
        v-show="formItems.imageUrls.selected && imgSourceType == 1"
      >
        <template #input>
          <uploader v-model="localImgs" :maxCount="5"> </uploader>
        </template>
      </van-field>
      <van-field
        :label="$t('图库图片')"
        v-show="formItems.imageUrls.selected && imgSourceType == 2"
      >
        <template #input>
          <div class="van-uploader__wrapper">
            <van-uploader v-model="libImgs" :show-upload="false" max-count="5">
            </van-uploader>
            <div class="van-uploader__upload" @click="onSelectByLibrary">
              <van-icon name="add-square" class="van-uploader__upload-icon" />
            </div>
          </div>
        </template>
      </van-field>
      <div style="margin: 16px" v-if="showPublishBtn">
        <van-button
          round
          block
          type="info"
          :loading="loading"
          @click="onSubmit"
          >{{ $t("立即发布") }}</van-button
        >
      </div>
    </van-form>
    <image-lib-picker
      v-model="imgLibraryShow"
      :max-count="imageLibMaxCount"
      @confirm="onConfirmLibImgs"
    />
  </div>
</template>

<script>
import {
  Button,
  Card,
  Form,
  Field,
  RadioGroup,
  Radio,
  CheckboxGroup,
  Checkbox,
  Popup,
  Icon,
  Grid,
  GridItem,
  Uploader as VanUploader,
  Image,
  CellGroup,
  Cell,
} from "vant";
import TitleBar from "../../components/title-bar.vue";
import Uploader from "../../components/uploader/index.vue";
import ImageLibPicker from "../../components/imagelib-picker/index.vue";

export default {
  components: {
    TitleBar,
    [Form.name]: Form,
    [Button.name]: Button,
    [Field.name]: Field,
    [Card.name]: Card,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
    [VanUploader.name]: VanUploader,
    [Uploader.name]: Uploader,
    [ImageLibPicker.name]: ImageLibPicker,
    [Popup.name]: Popup,
    [Icon.name]: Icon,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Image.name]: Image,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
  },
  computed: {
    title() {
      return this.type == 1 ? this.$t("网站信息修改") : this.$t("套餐信息修改");
    },
    formItemsList() {
      return Object.keys(this.formItems)
        .filter((x) => {
          if (x == "timeFragments") {
            return this.timeFragments.length > 0;
          } else {
            return true;
          }
        })
        .map((x) => {
          let res = this.formItems[x];
          res.key = x;
          return res;
        });
    },
    showPublishBtn() {
      return this.formItemsList.filter((x) => x.selected).length > 0;
    },
    imageLibMaxCount() {
      return 5 - this.libImgs.length;
    },
    timeFragments() {
      if (this.type == 1 && this.comboInfo && this.comboInfo.timeFragments)
        return this.comboInfo.timeFragments;
      else return [];
    },
  },
  data() {
    return {
      loading: false,
      id: 0,
      type: 1,
      comboInfo: {
        id: 0,
        name: null,
        summary: null,
        logoUrl: null,
        sites: [
          // {
          //   id: 1,
          //   name: "网站1",
          //   selected: false,
          // }
        ],
      },
      formItems: {
        timeFragments: {
          name: this.$t("时间段"),
          selected: false,
        },
        street: {
          name: this.$t("地区"),
          selected: false,
        },
        adType: {
          name: this.$t("类型"),
          selected: false,
        },
        adTel: {
          name: this.$t("广告电话"),
          selected: false,
        },
        privateTel: {
          name: this.$t("私人电话"),
          selected: false,
        },
        wechat: {
          name: this.$t("微信"),
          selected: false,
        },
        name: {
          name: this.$t("姓名"),
          selected: false,
        },
        age: {
          name: this.$t("年龄"),
          selected: false,
        },
        height: {
          name: this.$t("身高"),
          selected: false,
        },
        title: {
          name: this.$t("标题"),
          selected: false,
        },
        content: {
          name: this.$t("内容"),
          selected: false,
        },
        imageUrls: {
          name: this.$t("图片"),
          selected: false,
        },
      },
      imgSourceType: 1,
      localImgs: [],
      libImgs: [],
      imgLibraryShow: false,
      data: {
        timeFragments: [],
        siteIds: [],
        specId: undefined,
        areaId: undefined,
        street: "",
        adType: 1,
        adTel: "",
        privateTel: "",
        wechat: "",
        name: "",
        age: undefined,
        height: undefined,
        title: "",
        content: "",
        imageUrls: [],
      },
    };
  },
  async mounted() {
    let query = this.$route.query;
    this.id = query.id;
    this.type = query.type;

    let res = await this.$userApi.reserve.getReserveDetail({
      id: this.id,
    });
    if (res.status != 200) return;
    this.reserveInfo = res.data;
    if (this.type == 1 && res.data.reserveInfos.length > 0) {
      this.data = Object.assign(this.data, res.data.reserveInfos[0]);
    }

    if (this.type == 1) {
      res = await this.$userApi.site.getSiteDetail({
        id: this.reserveInfo.typeId,
      });
      if (res.status == 200) this.comboInfo = res.data;
    } else if (this.type == 2) {
      res = await this.$userApi.site.getSiteComboDetail({
        id: this.reserveInfo.typeId,
      });
      if (res.status != 200) return;
      let comboInfo = res.data;
      for (let site of comboInfo.sites) {
        site.selected = false;
      }
      this.comboInfo = comboInfo;
    }
  },
  methods: {
    onConfirmCity(res) {
      console.log(res);
    },
    onSelect() {
      this.imgLibraryShow = false;
    },
    onSelectImg() {
      this.actionShow = true;
    },
    onFileAfterRead() {},
    onSelectByLibrary() {
      this.imgLibraryShow = true;
    },
    onConfirmLibImgs(args) {
      for (let itm of args.items) {
        this.libImgs.push(itm);
      }
      this.imgLibraryShow = false;
    },
    async onSubmit() {
      this.loading = true;
      try {
        let siteIds;
        if (this.type == 2) {
          siteIds = this.comboInfo.sites
            .filter((x) => x.selected)
            .map((x) => x.site.id);
          if (siteIds.length < 1) {
            this.$toast.fail(this.$t("请至少选择一个网站"));
            return;
          }
        }

        let fields = this.formItemsList
          .filter((x) => x.selected)
          .map((x) => x.key);
        if (fields.length < 1) {
          this.$toast.fail(this.$t("请至少选择一项修改信息"));
          return;
        }

        if (this.imgSourceType == 1) {
          for (let img of this.localImgs) {
            if (img.status != "done") {
              console.info("当前图片状态", img);
              this.$toast(this.$t("请等待图片上传完成"));
              return;
            }
          }
          this.data.imageUrls = this.localImgs.map((x) => x.url);
        } else {
          this.data.imageUrls = this.libImgs.map((x) => x.url);
        }

        let res;
        if (this.type == 1) {
          res = await this.$userApi.reserve.updateSiteReserve({
            id: this.reserveInfo.id,
            fields,
            info: this.data,
          });
        } else {
          res = await this.$userApi.reserve.updateComboReserve({
            id: this.reserveInfo.id,
            siteIds,
            fields,
            info: this.data,
          });
        }
        if (res.status == 200) {
          await this.$dialog({ message: this.$t("恭喜，更新成功") });
          this.$router.go(-1);
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.form {
  padding-bottom: 1rem;
}

.muli-row {
  margin: 0.1rem 0;
  margin-right: 1rem;
}

.site-val {
  flex: none !important;
}

.imglib-content {
  padding: 0.5rem 0 0rem 0;
}

.form-items-box {
  padding: 0.5rem;
  background-color: white;
}

.block-bottom {
  margin-bottom: 0.5rem;
}

/deep/ .van-grid-item__content {
  padding: 0;

  .item-box {
    position: relative;
    width: 100%;
    height: 100%;

    .van-image {
      width: 100%;
      height: 100%;
    }

    .img-seled {
      position: absolute;
      right: 0.2rem;
      top: 0.2rem;
    }
  }
}
</style>